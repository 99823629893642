import React from 'react'
import {
  Row,
  Col,
  Modal
} from 'react-bootstrap'
import ButtonComponent from './ButtonComponent'

const ModalComponent = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      size={props.size || 'lg'}
      centered={props.centered || false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.modalTitle || ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.modalBody}</Modal.Body>
      <Modal.Footer>
        <Row style={{ width: '100%' }}>
          <Col xs='auto'>
            <ButtonComponent
              text='Back'
              iconClass='bi-arrow-left'
              onClick={props.handleClose}
              disabled={false}
              reverseIconPos
            />
          </Col>
          <Col />
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalComponent
