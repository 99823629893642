import app from "./app";
import handleSidebarMenuToggle from "./handleSidebarMenuToggle";

const handleSidebarMenu = () => {
  var menuBaseSelector = '.' + app.sidebar.class + ' .' + app.sidebar.menu.class + ' > .' + app.sidebar.menu.itemClass + '.' + app.sidebar.menu.hasSubClass;
  var submenuBaseSelector = ' > .' + app.sidebar.menu.submenu.class + ' > .' + app.sidebar.menu.itemClass + '.' + app.sidebar.menu.hasSubClass;

  // menu
  var menuLinkSelector = menuBaseSelector + ' > .' + app.sidebar.menu.itemLinkClass;
  var menus = [].slice.call(document.querySelectorAll(menuLinkSelector));
  handleSidebarMenuToggle(menus);

  // submenu lvl 1
  var submenuLvl1Selector = menuBaseSelector + submenuBaseSelector;
  var submenusLvl1 = [].slice.call(document.querySelectorAll(submenuLvl1Selector + ' > .' + app.sidebar.menu.itemLinkClass));
  handleSidebarMenuToggle(submenusLvl1);

  // submenu lvl 2
  var submenuLvl2Selector = menuBaseSelector + submenuBaseSelector + submenuBaseSelector;
  var submenusLvl2 = [].slice.call(document.querySelectorAll(submenuLvl2Selector + ' > .' + app.sidebar.menu.itemLinkClass));
  handleSidebarMenuToggle(submenusLvl2);
};

export default handleSidebarMenu;