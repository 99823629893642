import React from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  METRIC_COMPLETION,
  METRIC_CPM,
  METRIC_FILLRATE,
  METRIC_IMPRESSION,
  METRIC_INVENTORY,
  METRIC_REVENUE
} from '../constants'
import MetricsCardComponent from './MetricsCardComponent'

const CARD_TITLES = [
  METRIC_INVENTORY,
  METRIC_IMPRESSION,
  METRIC_REVENUE,
  METRIC_CPM,
  METRIC_FILLRATE,
  METRIC_COMPLETION
]

const generateMetricsCards = (
  cardsInARow,
  fetchingReports,
  mainMetricTexts,
  subMetricTextsOne,
  subMetricIconsOne,
  subMetricTextsTwo,
  subMetricIconsTwo,
  chartSeries,
  chartCategories,
  showCharts
) => {
  const els = []
  for (let i = 0; i < cardsInARow; i++) {
    els.push(
      <Col
        key={`metrics-card-col-${i}`}
        xl='4'
        lg='6'
      >
        <MetricsCardComponent
          key={`metrics-card-${i}`}
          fetchingReports={fetchingReports}
          titleText={CARD_TITLES[i]}
          mainMetricText={mainMetricTexts[i]}
          subMetricTextOne={subMetricTextsOne[i]}
          subMetricIconOne={subMetricIconsOne[i]}
          subMetricTextTwo={subMetricTextsTwo[i]}
          subMetricIconTwo={subMetricIconsTwo[i]}
          chartSeries={chartSeries ? chartSeries[i] : null}
          chartCategories={chartCategories}
          showCharts={showCharts}
        />
      </Col>
    )
  }
  return (
    <>
      {els}
    </>
  )
}

const MetricsCardRowComponent = (props) => {
  if (props && props.cardsInARow) {
    if (typeof props.cardsInARow !== 'number') {
      throw new Error('Attempted to invoke MetricsCardRowComponent with an invalid type for parameter cardsInARow!')
    } else {
      if (props.cardsInARow < 1 || props.cardsInARow > 6) {
        throw new Error('Attempted to invoke MetricsCardRowComponent with an invalid value for parameter cardsInARow, must be between 1 and 6!')
      }
    }
  } else throw new Error('Attempted to invoke MetricsCardRowComponent without a valid cardsInARow parameter!')

  return (
    <Row>
      {generateMetricsCards(
        props.cardsInARow,
        props.fetchingReports,
        props.mainMetricTexts,
        props.subMetricTextsOne,
        props.subMetricIconsOne,
        props.subMetricTextsTwo,
        props.subMetricIconsTwo,
        props.chartSeries,
        props.chartCategories,
        props.showCharts
      )}
    </Row>
  )
}

export default MetricsCardRowComponent
