import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { THEME_COLOR_LIGHT, THEME_COLOR_PRIMARY } from '../constants'

const ButtonComponent = (props) => {
  const normalStyle = {
    border: `1px solid ${THEME_COLOR_PRIMARY}`,
    background: 'none'
  }
  const hoverStyle = {
    border: `1px solid ${THEME_COLOR_LIGHT}`,
    background: 'none'
  }

  const [style, setStyle] = useState(normalStyle)

  return (
    <Button
      id={props.id || undefined}
      variant='primary'
      type='submit'
      rounded='true'
      className='text-white rounded bg-opacity-0'
      style={style}
      onClick={(e) => {
        e.preventDefault()
        props.onClick(e)
      }}
      onMouseOver={() => { setStyle(hoverStyle) }}
      onMouseOut={() => { setStyle(normalStyle) }}
      disabled={props.disabled}
    >
      {props.reverseIconPos
        ? (
          <>
            <i className={`bi ${props.iconClass}`} />
            <span>&ensp;&ensp;</span>
          </>
          )
        : null}
      {props.text}
      {!props.reverseIconPos
        ? (
          <>
            <span>&thinsp;&thinsp;</span>
            <i className={`bi ${props.iconClass}`} />
          </>
          )
        : null}
    </Button>
  )
}

export default ButtonComponent
