import React from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  APEX_CHART_TYPE_AREA,
  METRIC_CPM,
  METRIC_FILLRATE,
  METRIC_REVENUE
} from '../constants'
import ChartCardComponent from './ChartCardComponent'

const DUMMY_TITLE_TEXTS = [
  'Inventory x Impression',
  METRIC_REVENUE,
  METRIC_CPM,
  METRIC_FILLRATE
]

const generateChartCards = (cardsInARow, onClickExpand, series, categories) => {
  const els = []

  if (categories && categories.length) {
    if (categories.length >= 10 && categories.length < 20) {
      categories = categories.map((c, i) => {
        if (i % 2 === 0 || i === categories.length - 1) return c
        else return ' '
      })
    } else if (categories.length >= 20) {
      categories = categories.map((c, i) => {
        if (i % 3 === 0 || i === categories.length - 1) return c
        else return ' '
      })
    }
  }

  for (let i = 0; i < cardsInARow; i++) {
    els.push(
      <Col
        key={`chart-card-col-${i}`}
        xl='6'
        lg='12'
      >
        <ChartCardComponent
          key={`chart-card-${i}`}
          id={`chart-card-${i}`}
          titleText={DUMMY_TITLE_TEXTS[i]}
          chartType={APEX_CHART_TYPE_AREA}
          twoLines={i === 0}
          series={series[i]}
          categories={categories}
        />
      </Col>
    )
  }

  return (
    <>
      {els}
    </>
  )
}

const ChartCardRowComponent = (props) => {
  if (props && props.cardsInARow) {
    if (typeof props.cardsInARow !== 'number') {
      throw new Error('Attempted to invoke ChartCardRowComponent with an invalid type for parameter cardsInARow!')
    } else {
      if (props.cardsInARow < 1 || props.cardsInARow > 4) {
        throw new Error('Attempted to invoke ChartCardRowComponent with an invalid value for parameter cardsInARow, must be between 1 and 4!')
      }
    }
  } else throw new Error('Attempted to invoke ChartCardRowComponent without a valid cardsInARow parameter!')

  return (
    <Row>
      {generateChartCards(props.cardsInARow, props.onClickExpand, props.series, props.categories)}
    </Row>
  )
}

export default ChartCardRowComponent
