import React from 'react'
import {
  DateRangePicker,
  DateRange
} from 'react-date-range'
import {
  Row,
  Col
} from 'react-bootstrap'
import { THEME_COLOR_PRIMARY } from '../constants'

const DateRangePickerComponent = (props) => {
  return (
    <Row>
      <Col>
        {props.showDefinedDates
          ? (
            <DateRangePicker
              ranges={[props.dateSelection]}
              onChange={props.handleDateRangeSelect}
              maxDate={new Date()}
              color={THEME_COLOR_PRIMARY}
              rangeColors={[THEME_COLOR_PRIMARY]}
              disabled={props.disabled || false}
              months={props.months || 2}
              direction='horizontal'
              showSelectionPreview
            />
            )
          : (
            <DateRange
              ranges={[props.dateSelection]}
              onChange={props.handleDateRangeSelect}
              maxDate={new Date()}
              color={THEME_COLOR_PRIMARY}
              rangeColors={[THEME_COLOR_PRIMARY]}
              disabled={props.disabled || false}
              showSelectionPreview
            />
            )}
      </Col>
    </Row>
  )
}

export default DateRangePickerComponent
