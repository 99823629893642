// INTERNAL URLS
export const LOGIN_VIEW_URL = '/login'
export const DASHBOARD_VIEW_URL = '/dashboard'
export const BLOCKED_SOURCES_URL = '/blocked-sources'
export const PROFILE_VIEW_URL = '/user-profile'

// EXTERNAL URLS
export const GSM_HOMEPAGE_URL = 'https://www.globalsun.io/'

// APEX CHARTS
export const APEX_CHART_TYPE_LINE = 'line'
export const APEX_CHART_TYPE_AREA = 'area'
export const APEX_CHART_TYPE_BAR = 'bar'
export const APEX_CHART_TYPE_PIE = 'pie'
export const APEX_CHART_TYPE_DONUT = 'donut'

// HTTP
export const HTTP_GET_METHOD = 'GET'
export const HTTP_POST_METHOD = 'POST'
export const HTTP_PUT_METHOD = 'PUT'
export const HTTP_DELETE_METHOD = 'DELETE'

// COLORS
export const THEME_COLOR_PRIMARY = '#f9e80d'
export const THEME_COLOR_PRIMARY_RGB = '249, 232, 13'
export const THEME_COLOR_SECONDARY = '#f38812'
export const THEME_COLOR_SECONDARY_RGB = '248, 177, 14'
export const THEME_COLOR_BACKGROUND = '#1d2835'
export const THEME_COLOR_BACKGROUND_RGB = '29, 40, 53'
export const THEME_COLOR_LIGHT = '#fdf8b0'
export const THEME_COLOR_LIGHT_RGB = '252, 243, 141'
export const THEME_COLOR_TEXT = '#a0a0a0'
export const THEME_COLOR_TEXT_RGB = '160, 160, 160'

// TEXT STRINGS
export const TEXT_LOADING = 'Loading...'

// MISC STYLES /
export const SELECT_STYLE = {
  option: (provided) => ({
    ...provided,
    backgroundColor: THEME_COLOR_BACKGROUND,
    color: '#fff',
    borderColor: THEME_COLOR_TEXT
  }),
  control: (provided) => ({
    ...provided,
    height: 30,
    maxWidth: 400,
    minWidth: 280,
    backgroundColor: THEME_COLOR_BACKGROUND,
    color: '#fff',
    borderColor: THEME_COLOR_TEXT
  }),
  singleValue: (provided) => ({
    ...provided,
    backgroundColor: THEME_COLOR_BACKGROUND,
    color: '#fff',
    borderColor: THEME_COLOR_TEXT
  }),
  input: (provided) => ({
    ...provided,
    color: 'white'
  })
}

// MAGIC STRINGS
export const DATE_FORMAT_CALENDAR = 'DD-MM-YY'
export const DATE_FORMAT_USER_FACING = 'DD/MM/YYYY'
export const DATE_FORMAT_API = 'YYYY-MM-DD'

// METRICS
export const METRIC_REVENUE = 'Revenue'
export const METRIC_INVENTORY = 'Inventory'
export const METRIC_IMPRESSION = 'Impression'
export const METRIC_FILLRATE = 'Fill Rate'
export const METRIC_CPM = 'CPM'
export const METRIC_COMPLETION = 'Completion'
