import React from 'react'
import { Link } from 'react-router-dom'
import { PROFILE_VIEW_URL } from '../constants'

const HeaderUserComponent = (props) => {
  return (
    <div className='menu'>
      {props.showUserLink
        ? (
          <div className='menu-item dropdown dropdown-mobile-full'>
            <Link
              to={PROFILE_VIEW_URL}
              className='menu-link'
            >
              <div className='menu-img online'>
                <img
                  src={props.avatar && props.avatar.indexOf('cdn.globalsun') > -1 ? props.avatar : 'assets/img/user/profile.png'}
                  alt='Profile'
                  height='60'
                />
              </div>
              <div className='menu-text d-sm-block d-none'>{props.email ? props.email : 'User Profile'}</div>
            </Link>
          </div>
          )
        : null}
    </div>
  )
}

export default HeaderUserComponent
