import React from 'react'

const MobileMenuTogglerComponent = (props) => {
  return (
    <div className='mobile-toggler'>
      <button
        type='button'
        className='menu-toggler'
        data-toggle-class='app-sidebar-mobile-toggled'
        data-toggle-target='.app'
        disabled={!props || !props.allowMenuToggle}
        style={{ opacity: (!props || !props.allowMenuToggle) ? 0 : 1 }}
      >
        <span className='bar' />
        <span className='bar' />
        <span className='bar' />
      </button>
    </div>
  )
}

export default MobileMenuTogglerComponent
