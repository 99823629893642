import app from "./app";

const handleSidebarScrollMemory = function () {
  if (!app.isMobile) {
    try {
      if (typeof (Storage) !== 'undefined' && typeof (localStorage) !== 'undefined') {
        var elm = document.querySelector('.' + app.sidebar.class + ' [' + app.scrollBar.attr + ']');

        if (elm) {
          elm.onscroll = function () {
            localStorage.setItem(app.sidebar.scrollBar.localStorage, this.scrollTop);
          }
          var defaultScroll = localStorage.getItem(app.sidebar.scrollBar.localStorage);
          if (defaultScroll) {
            document.querySelector('.' + app.sidebar.class + ' [' + app.scrollBar.attr + ']').scrollTop = defaultScroll;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export default handleSidebarScrollMemory;