import React from 'react'
import ReactDOM from 'react-dom/client'
import AppRoot from './AppRoot'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import './styles/styles.scss'

const root = ReactDOM.createRoot(document.getElementById('app'))
root.render(
  <AppRoot />
)

unregisterServiceWorker()
