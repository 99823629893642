import CardComponent from './CardComponent'

const ErrorFallbackComponent = ({ error, resetErrorBoundary }) => {
  return (
    <CardComponent>
      <div style={{ width: '100%', textAlign: 'center', fontSize: '15px', color: '#ff6666' }}>
        <p style={{ marginBottom: '10px' }}>The following error occured:
          <span style={{ fontStyle: 'italic' }}>"{error.message}"</span>
        </p>
        <span>If this persists please report the issue</span>
      </div>
    </CardComponent>
  )
}

export default ErrorFallbackComponent
