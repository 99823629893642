import {
  APEX_CHART_TYPE_AREA,
  APEX_CHART_TYPE_BAR,
  APEX_CHART_TYPE_DONUT,
  APEX_CHART_TYPE_LINE,
  APEX_CHART_TYPE_PIE
} from '../constants'

const getPlotOptionsByChartType = (type) => {
  switch (type) {
    case APEX_CHART_TYPE_PIE:
    case APEX_CHART_TYPE_LINE:
    case APEX_CHART_TYPE_AREA:
      return {}
    case APEX_CHART_TYPE_BAR:
      return {
        bar: {
          horizontal: false,
          columnWidth: '65%',
          endingShape: 'rounded'
        }
      }
    case APEX_CHART_TYPE_DONUT:
      return {
        pie: {
          donut: {
            background: 'transparent'
          }
        }
      }
    default:
      throw new Error('Invalid type arg received at getPlotOptionsByChartType()!')
  }
}

export default getPlotOptionsByChartType
