import app from "./app";

const handleScrollTo = function () {
  var elmTriggerList = [].slice.call(document.querySelectorAll('[' + app.scrollTo.attr + ']'));
  var elmList = elmTriggerList.map(function (elm) {
    elm.onclick = function (e) {
      e.preventDefault();

      var targetAttr = (elm.getAttribute(app.scrollTo.target)) ? this.getAttribute(app.scrollTo.target) : this.getAttribute(app.scrollTo.linkTarget);
      var targetElm = document.querySelectorAll(targetAttr)[0];
      var targetHeader = document.querySelectorAll(app.header.id)[0];
      var targetHeight = targetHeader.offsetHeight;
      if (targetElm) {
        var targetTop = targetElm.offsetTop - targetHeight - 24;
        window.scrollTo({ top: targetTop, behavior: 'smooth' });
      }
    }
  });
};

export default handleScrollTo;