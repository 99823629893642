import {
  APEX_CHART_TYPE_AREA,
  APEX_CHART_TYPE_BAR,
  APEX_CHART_TYPE_DONUT,
  APEX_CHART_TYPE_LINE,
  APEX_CHART_TYPE_PIE
} from '../constants'

const getStrokeByChartType = (type) => {
  switch (type) {
    case APEX_CHART_TYPE_BAR:
    case APEX_CHART_TYPE_PIE:
      return {
        show: false
      }
    case APEX_CHART_TYPE_LINE:
    case APEX_CHART_TYPE_AREA:
      return {
        curve: 'smooth',
        width: 2
      }
    case APEX_CHART_TYPE_DONUT:
      return {
        show: false,
        curve: 'smooth',
        lineCap: 'butt',
        colors: 'rgba(0, 0, 0, .25)',
        width: 2,
        dashArray: 0
      }
    default:
      throw new Error('Invalid type arg received at getStrokeByChartType()!')
  }
}

export default getStrokeByChartType
