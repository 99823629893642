import {
  APEX_CHART_TYPE_AREA,
  APEX_CHART_TYPE_BAR,
  APEX_CHART_TYPE_DONUT,
  APEX_CHART_TYPE_LINE,
  APEX_CHART_TYPE_PIE,
  THEME_COLOR_PRIMARY,
  THEME_COLOR_PRIMARY_RGB
} from '../constants'

const getColorsByChartType = (type) => {
  switch (type) {
    case APEX_CHART_TYPE_BAR:
    case APEX_CHART_TYPE_LINE:
    case APEX_CHART_TYPE_AREA:
      return [THEME_COLOR_PRIMARY]
    case APEX_CHART_TYPE_PIE:
      return [
        'rgba(' + THEME_COLOR_PRIMARY_RGB + ', 1)',
        'rgba(' + THEME_COLOR_PRIMARY_RGB + ', .75)',
        'rgba(' + THEME_COLOR_PRIMARY_RGB + ', .5)'
      ]
    case APEX_CHART_TYPE_DONUT:
      return [
        'rgba(' + THEME_COLOR_PRIMARY_RGB + ', .15)',
        'rgba(' + THEME_COLOR_PRIMARY_RGB + ', .35)',
        'rgba(' + THEME_COLOR_PRIMARY_RGB + ', .55)',
        'rgba(' + THEME_COLOR_PRIMARY_RGB + ', .75)',
        'rgba(' + THEME_COLOR_PRIMARY_RGB + ', .95)'
      ]
    default:
      throw new Error('Invalid type arg received at getColorsByChartType()!')
  }
}

export default getColorsByChartType
