import React from 'react'
import { Link } from 'react-router-dom'

const ScrollToTopButtonComponent = () => {
  return (
    <Link
      to='#'
      data-toggle='scroll-to-top'
      className='btn-scroll-top fade'
    >
      <i className='fa fa-arrow-up' />
    </Link>
  )
}

export default ScrollToTopButtonComponent
