import React from 'react'
import { Link } from 'react-router-dom'

const SidebarItemComponent = (props) => {
  const className = props && props.active ? 'menu-item active' : 'menu-item'
  return (
    <div className={className}>
      <Link
        id={props.id}
        to={props.href}
        className='menu-link'
        onClick={() => { if (props.onClick && typeof props.onClick === 'function') props.onClick() }}
      >
        <span className='menu-icon'>
          <i className={`bi ${props.iconClass}`} />
        </span>
        <span className='menu-text'>{props.text}</span>
      </Link>
    </div>
  )
}

export default SidebarItemComponent
