import React from 'react'

const DesktopMenuTogglerComponent = (props) => {
  return (
    <div className='desktop-toggler'>
      <button
        type='button'
        className='menu-toggler'
        data-toggle-class='app-sidebar-collapsed'
        data-dismiss-class='app-sidebar-toggled'
        data-toggle-target='.app'
        disabled={!props || !props.allowMenuToggle}
        style={{ opacity: (!props || !props.allowMenuToggle) ? 0 : 1 }}
      >
        <span className='bar' />
        <span className='bar' />
        <span className='bar' />
      </button>
    </div>
  )
}

export default DesktopMenuTogglerComponent
