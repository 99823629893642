import Cookies from 'js-cookie';
import app from './app';

const handleThemePanel = function () {
  // 12.1 Theme Panel - Toggle / Dismiss
  var elmList = [].slice.call(document.querySelectorAll('[' + app.themePanel.toggleAttr + ']'));

  elmList.map(function (elm) {
    elm.onclick = function (e) {
      e.preventDefault();

      var targetContainer = document.querySelector('.' + app.themePanel.class);
      var targetExpand = false;

      if (targetContainer.classList.contains(app.themePanel.activeClass)) {
        targetContainer.classList.remove(app.themePanel.activeClass);
      } else {
        targetContainer.classList.add(app.themePanel.activeClass);
        targetExpand = true;
      }
      if (Cookies) {
        Cookies.set(app.themePanel.cookieName, targetExpand);
      }
    }
  });

  // 12.2 Theme Panel - Page Load Cookies 
  if (Cookies) {
    var themePanelExpand = Cookies.get(app.themePanel.cookieName);

    if (themePanelExpand == 'true' || typeof themePanelExpand == 'undefined') {
      var elm = document.querySelector('[' + app.themePanel.toggleAttr + ']');
      if (elm) {
        elm.click();
      }
    }
  }


  // 12.3 Theme Panel - Theme Selector
  var elmList = [].slice.call(document.querySelectorAll('.' + app.themePanel.class + ' [' + app.themePanel.theme.toggleAttr + ']'));
  elmList.map(function (elm) {
    elm.onclick = function () {
      for (var x = 0; x < document.body.classList.length; x++) {
        var targetClass = document.body.classList[x];
        if (targetClass.search('theme-') > -1) {
          document.body.classList.remove(targetClass);
        }
      }

      var targetTheme = this.getAttribute(app.themePanel.theme.classAttr);
      var targetThemeList = [].slice.call(document.querySelectorAll('.' + app.themePanel.class + ' [' + app.themePanel.theme.toggleAttr + ']'));

      if (targetTheme) {
        document.body.classList.add(targetTheme);
      }
      targetThemeList.map(function (targetElm) {
        if (targetElm.getAttribute(app.themePanel.theme.classAttr) != targetTheme) {
          targetElm.closest('.' + app.themePanel.themeListItemCLass).classList.remove(app.themePanel.theme.activeClass);
        }
      });

      this.closest('.' + app.themePanel.themeListItemCLass).classList.add(app.themePanel.theme.activeClass);

      if (Cookies) {
        Cookies.set(app.themePanel.theme.cookieName, targetTheme);
        app.color.theme = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
        app.color.themeRgb = (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim();

        document.dispatchEvent(new Event('theme-reload'));
      }
    }
  });

  if (Cookies) {
    if (Cookies.get(app.themePanel.theme.cookieName)) {
      var targetElm = document.querySelector('.' + app.themePanel.class + ' [' + app.themePanel.theme.toggleAttr + ']' + '[' + app.themePanel.theme.classAttr + '="' + Cookies.get(app.themePanel.theme.cookieName) + '"]');
      targetElm.click();

      app.color.theme = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
      app.color.themeRgb = (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim();

      document.dispatchEvent(new Event('theme-reload'));
    }
  }


  // 12.4 Theme Panel - Background Selector
  var elmList = [].slice.call(document.querySelectorAll('.' + app.themePanel.class + ' [' + app.themePanel.themeCover.toggleAttr + ']'));
  elmList.map(function (elm) {
    elm.onclick = function (e) {
      e.preventDefault();

      var htmlElm = document.querySelector('html');
      var targetTheme = this.getAttribute(app.themePanel.themeCover.classAttr);
      for (var x = 0; x < document.documentElement.classList.length; x++) {
        var targetClass = document.documentElement.classList[x];
        if (targetClass.search('bg-cover-') > -1) {
          htmlElm.classList.remove(targetClass);
        }
      }

      if (targetTheme) {
        htmlElm.classList.add(targetTheme);
      }

      var targetCoverList = [].slice.call(document.querySelectorAll('.' + app.themePanel.class + ' [' + app.themePanel.themeCover.toggleAttr + ']'));
      targetCoverList.map(function (targetElm) {
        if (targetElm.getAttribute(app.themePanel.themeCover.toggleAttr) != targetTheme) {
          targetElm.closest('.' + app.themePanel.themeCoverItemClass).classList.remove(app.themePanel.themeCover.activeClass);
        }
      });

      this.closest('.' + app.themePanel.themeCoverItemClass).classList.add(app.themePanel.themeCover.activeClass);
      if (Cookies) {
        Cookies.set(app.themePanel.themeCover.cookieName, targetTheme);
      }
    };
  });

  if (Cookies) {
    if (Cookies.get(app.themePanel.themeCover.cookieName)) {
      var targetElm = document.querySelector('.' + app.themePanel.class + ' [' + app.themePanel.themeCover.toggleAttr + ']' + '[' + app.themePanel.themeCover.classAttr + '="' + Cookies.get(app.themePanel.themeCover.cookieName) + '"]');
      if (targetElm) {
        targetElm.click();
      }
    }
  }
};

export default handleThemePanel;
