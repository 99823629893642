import app from "./app";
import generateScrollbar from "./generateScrollbar";

const handleScrollbar = function () {
  var elms = document.querySelectorAll('[' + app.scrollBar.attr + ']');

  for (var i = 0; i < elms.length; i++) {
    generateScrollbar(elms[i])
  }
};

export default handleScrollbar;