import {
  Tooltip,
  Popover
} from 'bootstrap'
import app from "./app";

const handelTooltipPopoverActivation = function () {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[' + app.bootstrap.tooltip.attr + ']'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[' + app.bootstrap.popover.attr + ']'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl);
  });
};

export default handelTooltipPopoverActivation;