import PerfectScrollbar from "perfect-scrollbar";
import app from "./app";

const generateScrollbar = function (elm) {

  if (elm.scrollbarInit || (app.isMobile && elm.getAttribute(app.scrollBar.skipMobileAttr))) {
    return;
  }
  var dataHeight = (!elm.getAttribute(app.scrollBar.heightAttr)) ? elm.offsetHeight : elm.getAttribute(app.scrollBar.heightAttr);

  elm.style.height = dataHeight;
  elm.scrollbarInit = true;

  if (app.isMobile) {
    elm.style.overflowX = 'scroll';
  } else {
    var dataWheelPropagation = (elm.getAttribute(app.scrollBar.wheelPropagationAttr)) ? elm.getAttribute(app.scrollBar.wheelPropagationAttr) : false;

    if (elm.closest('.' + app.sidebar.class) && elm.closest('.' + app.sidebar.class).length !== 0) {
      app.sidebar.scrollBar.dom = new PerfectScrollbar(elm, {
        wheelPropagation: dataWheelPropagation
      });
    } else {
      new PerfectScrollbar(elm, {
        wheelPropagation: dataWheelPropagation
      });
    }
  }
  elm.setAttribute(app.init.attr, true);
};

export default generateScrollbar;