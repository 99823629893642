import React from 'react'
import { Navigate } from 'react-router-dom'

const RedirectView = (props) => {
  return (
    <Navigate to={props.url} />
  )
}

export default RedirectView
