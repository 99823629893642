import app from "./app";

const handleSidebarMenuToggle = function (menus) {
  menus.map(function (menu) {
    menu.onclick = function (e) {
      e.preventDefault();
      var target = this.nextElementSibling;
      menus.map(function (m) {
        var otherTarget = m.nextElementSibling;
        if (otherTarget !== target) {
          otherTarget.style.display = 'none';
          otherTarget.closest('.' + app.sidebar.menu.itemClass).classList.remove(app.sidebar.menu.expandClass);
        }
      });

      var targetItemElm = target.closest('.' + app.sidebar.menu.itemClass);

      if (targetItemElm.classList.contains(app.sidebar.menu.expandClass) || (targetItemElm.classList.contains(app.sidebar.menu.activeClass) && !target.style.display)) {
        targetItemElm.classList.remove(app.sidebar.menu.expandClass);
        target.style.display = 'none';
      } else {
        targetItemElm.classList.add(app.sidebar.menu.expandClass);
        target.style.display = 'block';
      }
    }
  });
};

export default handleSidebarMenuToggle;