import React, {
  useCallback,
  useMemo
} from 'react'
import { useDropzone } from 'react-dropzone'

const baseStyle = {
  flex: 1,
  display: 'flex',
  padding: '20px',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
}

const acceptStyle = {
}

const rejectStyle = {
}

const FileDropzoneComponent = (props) => {
  const onDrop = useCallback(acceptedFiles => {
    props.onDrop(acceptedFiles)
  }, [])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png']
    }
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  return (
    <div {...getRootProps(style)}>
      <input {...getInputProps()} />
      {
        isDragActive
          ? <p>Drop the files HERE ...</p>
          : (
            <>
              <p style={{ cursor: 'pointer' }}>Drag 'n' drop new image HERE, or click to browse...</p>
              <p>Accepted formats:&ensp;<span style={{ fontStyle: 'italic' }}>.png</span></p>
            </>
            )
      }
    </div>
  )
}

export default FileDropzoneComponent
