import React from 'react'
import { useLocation } from 'react-router-dom'
import {
  Row,
  Col
} from 'react-bootstrap'
import SidebarItemComponent from './SidebarItemComponent'
import {
  BLOCKED_SOURCES_URL,
  DASHBOARD_VIEW_URL,
  PROFILE_VIEW_URL
} from '../constants.js'
import packageJSON from '../../package.json'
import deleteAllCookies from '../utils/deleteAllCookies'
import collapseAccordion from '../utils/collapseAccordion'

const logout = () => {
  deleteAllCookies()
  window.location.href = '/'
}

const SidebarComponent = () => {
  const location = useLocation()
  return (
    <div
      id='sidebar'
      className='app-sidebar'
      onClick={() => { collapseAccordion(document) }}
    >
      <div
        className='app-sidebar-content'
        data-scrollbar='true'
        data-height='100%'
        data-wheel-propagation
      >
        <div className='menu'>
          <div className='menu-header'>Metrics</div>
          <SidebarItemComponent
            id='dashboard-sidebar-link'
            active={location.pathname === DASHBOARD_VIEW_URL}
            href={DASHBOARD_VIEW_URL}
            iconClass='bi-bar-chart'
            text='Dashboard'
          />
          <SidebarItemComponent
            id='blocked-sources-sidebar-link'
            active={location.pathname === BLOCKED_SOURCES_URL}
            href={BLOCKED_SOURCES_URL}
            iconClass='bi-slash-circle'
            text='Blocked Sources'
          />
          <div className='menu-header'>My Account</div>
          <SidebarItemComponent
            id='user-profile-sidebar-link'
            active={location.pathname === PROFILE_VIEW_URL}
            href={PROFILE_VIEW_URL}
            iconClass='bi-person'
            text='Profile'
          />
          <SidebarItemComponent
            id='logout-sidebar-link'
            href='#'
            iconClass='bi-box-arrow-left'
            text='Logout'
            onClick={(e) => { logout() }}
          />
        </div>
        <Row
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0
          }}
        >
          <Col />
          <Col xs='auto'>
            <div
              id='version-number'
              style={{
                paddingBottom: '22px',
                paddingLeft: '10px'
              }}
            >
              v{packageJSON.version}
            </div>
          </Col>
          <Col xs='1' />
        </Row>
      </div>
    </div>
  )
}

export default SidebarComponent
