import React from 'react'
import Select from 'react-select'
import { SELECT_STYLE } from '../constants'

const SelectComponent = (props) => {
  const {
    id,
    isSearchable,
    maxMenuHeight,
    isDisabled,
    value,
    onChange,
    options
  } = props

  if (!value) throw new Error('Cannot invoke SelectComponent without a valid value prop!')
  if (!onChange || typeof onChange !== 'function') throw new Error('Cannot invoke SelectComponent without a valid onChange prop!')

  return (
    <Select
      id={id || undefined}
      type='select'
      isSearchable={isSearchable || true}
      maxMenuHeight={maxMenuHeight || 180}
      isDisabled={isDisabled || false}
      value={value}
      onChange={onChange}
      options={options || []}
      styles={SELECT_STYLE}
    />
  )
}

export default SelectComponent
