import millify from 'millify'
import {
  METRIC_CPM,
  METRIC_REVENUE
} from '../constants'

const getYAxisFormatter = (titleText) => {
  const isCurrency = titleText === METRIC_REVENUE || titleText === METRIC_CPM
  return (value) => isCurrency ? `$${millify(value, { precision: 2 })}` : millify(value, { precision: 1 })
}

export default getYAxisFormatter
