import React from 'react'
import { Spinner } from 'react-bootstrap'
import { THEME_COLOR_PRIMARY } from '../constants'

const ThemedSpinnerComponent = () => {
  return (
    <Spinner
      animation='border'
      role='status'
      style={{
        color: THEME_COLOR_PRIMARY
      }}
    />
  )
}

export default ThemedSpinnerComponent
