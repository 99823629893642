import React from 'react'
import Cookies from 'js-cookie'
import DesktopMenuTogglerComponent from './DesktopMenuTogglerComponent'
import MobileMenuTogglerComponent from './MobileMenuTogglerComponent'
import LogoBannerComponent from './LogoBannerComponent'
import HeaderUserComponent from './HeaderUserComponent'

const HeaderComponent = (props) => {
  const email = Cookies.get('user-email')
  const avatar = Cookies.get('user-avatar')

  return (
    <div
      id='header'
      className='app-header'
    >
      <DesktopMenuTogglerComponent allowMenuToggle={props.allowMenuToggle | false} />
      <MobileMenuTogglerComponent allowMenuToggle={props.allowMenuToggle | false} />
      <LogoBannerComponent />
      <HeaderUserComponent
        showUserLink={props.showUserLink | false}
        email={email}
        avatar={avatar}
      />
    </div>
  )
}

export default HeaderComponent
