import React from 'react'

const MobileSidebarBackdropComponent = () => {
  return (
    <button
      className='app-sidebar-mobile-backdrop'
      data-toggle-target='.app'
      data-toggle-class='app-sidebar-mobile-toggled'
    />
  )
}

export default MobileSidebarBackdropComponent
