const app = {
  id: '#app',
  isMobile: ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || window.innerWidth < 992),
  bootstrap: {
    tooltip: {
      attr: 'data-bs-toggle="tooltip"'
    },
    popover: {
      attr: 'data-bs-toggle="popover"'
    },
    modal: {
      attr: 'data-bs-toggle="modal"',
      dismissAttr: 'data-bs-dismiss="modal"',
      event: {
        hidden: 'hidden.bs.modal'
      }
    },
    nav: {
      class: 'nav',
      tabs: {
        class: 'nav-tabs',
        activeClass: 'active',
        itemClass: 'nav-item',
        itemLinkClass: 'nav-link'
      }
    }
  },
  header: {
    id: '#header',
    class: 'app-header',
    hasScrollClass: 'has-scroll'
  },
  sidebar: {
    id: '#sidebar',
    class: 'app-sidebar',
    scrollBar: {
      localStorage: 'appSidebarScrollPosition',
      dom: ''
    },
    menu: {
      class: 'menu',
      initAttr: 'data-init',
      animationTime: 0,
      itemClass: 'menu-item',
      itemLinkClass: 'menu-link',
      hasSubClass: 'has-sub',
      activeClass: 'active',
      expandingClass: 'expanding',
      expandClass: 'expand',
      submenu: {
        class: 'menu-submenu',
      }
    },
    mobile: {
      toggleAttr: 'data-toggle="app-sidebar-mobile"',
      dismissAttr: 'data-dismiss="app-sidebar-mobile"',
      toggledClass: 'app-sidebar-mobile-toggled',
      closedClass: 'app-sidebar-mobile-closed',
      backdrop: {
        class: 'app-sidebar-mobile-backdrop'
      }
    },
    minify: {
      toggleAttr: 'data-toggle="app-sidebar-minify"',
      toggledClass: 'app-sidebar-minified',
      cookieName: 'app-sidebar-minified'
    },
    floatSubmenu: {
      id: '#app-sidebar-float-submenu',
      dom: '',
      timeout: '',
      class: 'app-sidebar-float-submenu',
      container: {
        class: 'app-sidebar-float-submenu-container'
      },
      arrow: {
        id: '#app-sidebar-float-submenu-arrow',
        class: 'app-sidebar-float-submenu-arrow'
      },
      line: {
        id: '#app-sidebar-float-submenu-line',
        class: 'app-sidebar-float-submenu-line'
      },
      overflow: {
        class: 'overflow-scroll mh-100vh'
      }
    },
    search: {
      class: 'menu-search',
      toggleAttr: 'data-sidebar-search="true"',
      hideClass: 'd-none',
      foundClass: 'has-text'
    },
    transparent: {
      class: 'app-sidebar-transparent'
    }
  },
  scrollBar: {
    attr: 'data-scrollbar="true"',
    skipMobileAttr: 'data-skip-mobile',
    heightAttr: 'data-height',
    wheelPropagationAttr: 'data-wheel-propagation'
  },
  content: {
    id: '#content',
    class: 'app-content',
    fullHeight: {
      class: 'app-content-full-height'
    },
    fullWidth: {
      class: 'app-content-full-width'
    }
  },
  layout: {
    sidebarLight: {
      class: 'app-with-light-sidebar'
    },
    sidebarEnd: {
      class: 'app-with-end-sidebar'
    },
    sidebarWide: {
      class: 'app-with-wide-sidebar'
    },
    sidebarMinified: {
      class: 'app-sidebar-minified'
    },
    sidebarTwo: {
      class: 'app-with-two-sidebar'
    },
    withoutHeader: {
      class: 'app-without-header'
    },
    withoutSidebar: {
      class: 'app-without-sidebar'
    },
    topMenu: {
      class: 'app-with-top-menu'
    },
    boxedLayout: {
      class: 'boxed-layout'
    }
  },
  scrollToTopBtn: {
    showClass: 'show',
    heightShow: 200,
    toggleAttr: 'data-toggle="scroll-to-top"',
    scrollSpeed: 500
  },
  scrollTo: {
    attr: 'data-toggle="scroll-to"',
    target: 'data-target',
    linkTarget: 'href'
  },
  themePanel: {
    class: 'app-theme-panel',
    toggleAttr: 'data-toggle="theme-panel-expand"',
    cookieName: 'app-theme-panel-expand',
    activeClass: 'active',
    themeListCLass: 'app-theme-list',
    themeListItemCLass: 'app-theme-list-item',
    themeCoverClass: 'app-theme-cover',
    themeCoverItemClass: 'app-theme-cover-item',
    theme: {
      toggleAttr: 'data-toggle="theme-selector"',
      classAttr: 'data-theme-class',
      cookieName: 'app-theme',
      activeClass: 'active'
    },
    themeCover: {
      toggleAttr: 'data-toggle="theme-cover-selector"',
      classAttr: 'data-theme-cover-class',
      cookieName: 'app-theme-cover',
      activeClass: 'active'
    }
  },
  dismissClass: {
    toggleAttr: 'data-dismiss-class',
    targetAttr: 'data-dismiss-target'
  },
  toggleClass: {
    toggleAttr: 'data-toggle-class',
    targetAttr: 'data-toggle-target'
  },
  font: {
    family: (getComputedStyle(document.body).getPropertyValue('--bs-body-font-family')).trim(),
    size: (getComputedStyle(document.body).getPropertyValue('--bs-body-font-size')).trim(),
    weight: (getComputedStyle(document.body).getPropertyValue('--bs-body-font-weight')).trim()
  },
  color: {
    theme: (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim(),
    blue: (getComputedStyle(document.body).getPropertyValue('--bs-blue')).trim(),
    green: (getComputedStyle(document.body).getPropertyValue('--bs-green')).trim(),
    orange: (getComputedStyle(document.body).getPropertyValue('--bs-orange')).trim(),
    red: (getComputedStyle(document.body).getPropertyValue('--bs-red')).trim(),
    cyan: (getComputedStyle(document.body).getPropertyValue('--bs-cyan')).trim(),
    purple: (getComputedStyle(document.body).getPropertyValue('--bs-purple')).trim(),
    yellow: (getComputedStyle(document.body).getPropertyValue('--bs-yellow')).trim(),
    indigo: (getComputedStyle(document.body).getPropertyValue('--bs-indigo')).trim(),
    pink: (getComputedStyle(document.body).getPropertyValue('--bs-pink')).trim(),
    black: (getComputedStyle(document.body).getPropertyValue('--bs-black')).trim(),
    white: (getComputedStyle(document.body).getPropertyValue('--bs-white')).trim(),
    gray: (getComputedStyle(document.body).getPropertyValue('--bs-gray')).trim(),
    dark: (getComputedStyle(document.body).getPropertyValue('--bs-dark')).trim(),
    gray100: (getComputedStyle(document.body).getPropertyValue('--bs-gray-100')).trim(),
    gray200: (getComputedStyle(document.body).getPropertyValue('--bs-gray-200')).trim(),
    gray300: (getComputedStyle(document.body).getPropertyValue('--bs-gray-300')).trim(),
    gray400: (getComputedStyle(document.body).getPropertyValue('--bs-gray-400')).trim(),
    gray500: (getComputedStyle(document.body).getPropertyValue('--bs-gray-500')).trim(),
    gray600: (getComputedStyle(document.body).getPropertyValue('--bs-gray-600')).trim(),
    gray700: (getComputedStyle(document.body).getPropertyValue('--bs-gray-700')).trim(),
    gray800: (getComputedStyle(document.body).getPropertyValue('--bs-gray-800')).trim(),
    gray900: (getComputedStyle(document.body).getPropertyValue('--bs-gray-900')).trim(),

    themeRgb: (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim(),
    blueRgb: (getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb')).trim(),
    greenRgb: (getComputedStyle(document.body).getPropertyValue('--bs-green-rgb')).trim(),
    orangeRgb: (getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb')).trim(),
    redRgb: (getComputedStyle(document.body).getPropertyValue('--bs-red-rgb')).trim(),
    cyanRgb: (getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb')).trim(),
    purpleRgb: (getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb')).trim(),
    yellowRgb: (getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb')).trim(),
    indigoRgb: (getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb')).trim(),
    pinkRgb: (getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb')).trim(),
    blackRgb: (getComputedStyle(document.body).getPropertyValue('--bs-black-rgb')).trim(),
    whiteRgb: (getComputedStyle(document.body).getPropertyValue('--bs-white-rgb')).trim(),
    grayRgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb')).trim(),
    darkRgb: (getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb')).trim(),
    gray100Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb')).trim(),
    gray200Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb')).trim(),
    gray300Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb')).trim(),
    gray400Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb')).trim(),
    gray500Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb')).trim(),
    gray600Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb')).trim(),
    gray700Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb')).trim(),
    gray800Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb')).trim(),
    gray900Rgb: (getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb')).trim()
  },
  card: {
    expand: {
      status: false,
      toggleAttr: 'data-toggle="card-expand"',
      toggleTitle: 'Expand / Compress',
      class: 'card-expand'
    }
  },
  init: {
    attr: 'data-init'
  }
};

export default app;