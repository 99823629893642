import React, {
  useState,
  useEffect
} from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import { getBlockedSources } from '../api/api'
import ThemedSpinnerComponent from '../components/ThemedSpinnerComponent'
import CardComponent from '../components/CardComponent'
import ButtonComponent from '../components/ButtonComponent'
import { THEME_COLOR_PRIMARY } from '../constants'

const BlockedSourcesView = () => {
  const [blockedSources, setBlockedSources] = useState([])
  // const [newBlockedSource, setNewBlockedSource] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getBlockedSources()
      .then(blockedSources => {
        setBlockedSources(blockedSources)
        setLoading(false)
      })
  }, [])

  const addNewUrl = () => {
    // TODO
  }

  if (loading) {
    return (
      <Row style={{ height: '400px' }}>
        <Col />
        <Col xs='auto'>
          <div style={{ paddingTop: '150px' }}>
            <ThemedSpinnerComponent />
          </div>
        </Col>
        <Col />
      </Row>
    )
  } else {
    return (
      <Row style={{ margin: '0px' }}>
        <Col>
          <CardComponent>
            <Row>
              <Col xs='8'>
                <span>Here you can add URLs which you wish to block.</span>
              </Col>
              <Col xs='4'>
                <ButtonComponent
                  text='Add URL'
                  iconClass='bi-plus'
                  onClick={() => { addNewUrl() }}
                />
              </Col>
            </Row>

            <br />
            <br />
            <table className='w-100 small mb-0 text-truncate text-white'>
              <thead>
                <tr className='text-white text-opacity-60'>
                  <th>URL</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {blockedSources.map((b, i) => {
                  return (
                    <tr
                      key={`blocked-sources-table-row-${i}`}
                      style={{ color: '#fff' }}
                    >
                      <td>{b.url}</td>
                      <td style={{ color: b.status ? THEME_COLOR_PRIMARY : '#ff6666' }}>
                        {b.status ? 'Blocked' : 'Processing'}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardComponent>
        </Col>
      </Row>
    )
  }
}

export default BlockedSourcesView
