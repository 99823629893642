import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import ReactExport from 'react-export-excel'
import { CSVLink } from 'react-csv'
import ButtonComponent from './ButtonComponent'
import CardComponent from './CardComponent'
import { THEME_COLOR_PRIMARY } from '../constants'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ExportComponent = (props) => {
  const [exportingToExcel, setExportingToExcel] = useState(false)
  const [exportingToCsv, setExportingToCsv] = useState(false)

  const exportToExcel = () => {
    setExportingToExcel(true)
    setTimeout(() => { setExportingToExcel(false) }, 1000)
  }

  const exportToCsv = () => {
    setExportingToCsv(true)
    csvLink.link.click()
    setTimeout(() => { setExportingToCsv(false) }, 1000)
  }

  const excelButton = (
    <ButtonComponent
      text='Excel'
      iconClass='bi-file-earmark-spreadsheet'
      onClick={exportToExcel}
      disabled={props.fetchingReports || exportingToCsv}
    />
  )

  const excelElement = (
    <ExcelFile
      element={excelButton}
      filename={`Publisher Report Details ${props.fromDate} to ${props.toDate}`}
    >
      <ExcelSheet
        data={props.data}
        name='Sorted by Sites'
      >
        <ExcelColumn
          label='Site'
          value='site'
        />
        <ExcelColumn
          label='Date'
          value='date'
        />
        <ExcelColumn
          label='Total Inventory'
          value='inventory'
        />
        <ExcelColumn
          label='Total Impression'
          value='impression'
        />
        <ExcelColumn
          label='Total Revenue'
          value='revenue'
        />
        <ExcelColumn
          label='Average CPM'
          value='cpm'
        />
        <ExcelColumn
          label='Average Fill Rate'
          value='fillRate'
        />
        <ExcelColumn
          label='Total Completion'
          value='completed'
        />
      </ExcelSheet>
    </ExcelFile>
  )

  const csvButton = (
    <ButtonComponent
      text='CSV'
      iconClass='bi-filetype-csv'
      onClick={() => { exportToCsv() }}
      disabled={props.fetchingReports || exportingToExcel}
    />
  )

  const csvHeaders = [
    { label: 'Site', key: 'site' },
    { label: 'Total Inventory', key: 'inventory' },
    { label: 'Total Impression', key: 'impression' },
    { label: 'Total Revenue', key: 'revenue' },
    { label: 'Average CPM', key: 'cpm' },
    { label: 'Average Fill Rate', key: 'fillRate' },
    { label: 'Total Completion', key: 'completed' }
  ]

  let csvLink
  const csvElement = (
    <>
      {csvButton}
      <CSVLink
        data={props.data}
        headers={csvHeaders}
        filename={`Publisher Report Details ${props.fromDate} to ${props.toDate}`}
        style={{ display: 'none' }}
        ref={(r) => (csvLink = r)}
      >
        Download Me
      </CSVLink>
    </>
  )

  const spinner = (
    <Spinner
      animation='border'
      role='status'
      style={{
        color: THEME_COLOR_PRIMARY,
        height: '20px',
        width: '20px',
        borderWidth: '3px'
      }}
    />
  )

  const mobileSpan = (
    <span style={{ fontWeight: 400 }}>
      Export data to
      &ensp;{exportingToExcel ? spinner : excelElement}&ensp;
      or
      &ensp;{exportingToCsv ? spinner : csvElement}
    </span>
  )

  const desktopSpan = (
    <span style={{ fontWeight: 400 }}>
      Here you can export all data for selected date range to
      &ensp;&ensp;{exportingToExcel ? spinner : excelElement}&ensp;&ensp;
      or
      &ensp;&ensp;{exportingToCsv ? spinner : csvElement}
    </span>
  )

  return (
    <CardComponent>
      {window.innerWidth < 600 ? mobileSpan : desktopSpan}
    </CardComponent>
  )
}

export default ExportComponent
