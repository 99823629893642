import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card
} from 'react-bootstrap'
import { countryToAlpha2 } from 'country-to-iso'
import { VectorMap } from '@react-jvectormap/core'
import { worldMill } from '@react-jvectormap/world'
import Select from 'react-select'
import {
  THEME_COLOR_PRIMARY,
  SELECT_STYLE,
  THEME_COLOR_SECONDARY,
  THEME_COLOR_LIGHT,
  METRIC_REVENUE,
  METRIC_INVENTORY,
  METRIC_IMPRESSION,
  METRIC_CPM,
  METRIC_COMPLETION
} from '../constants'
import ThemedSpinnerComponent from './ThemedSpinnerComponent'

let regionValues = {}

const WorldMapComponent = (props) => {
  const [selectedMetric, setSelectedMetric] = useState({
    label: 'Impression',
    value: 'Impression'
  })
  const [loadingMap, setLoadingMap] = useState(false)

  useEffect(() => {
    regionValues = calculateRegionValues()
    setLoadingMap(true)
    setTimeout(() => { setLoadingMap(false) }, 800)
  }, [])

  const getSelectedMetric = (report, e) => {
    switch (e ? e.value : selectedMetric.value) {
      case METRIC_IMPRESSION:
        return parseInt(report.impression)
      case METRIC_INVENTORY:
        return parseInt(report.inventory)
      case METRIC_REVENUE:
        return parseFloat(report.revenue)
      case METRIC_CPM:
        return parseFloat(report.ecpm)
      case METRIC_COMPLETION:
        return parseInt(report.complete)
      default:
        throw new Error('Attempted to invoke getSelectedMetric() with an invalid selectedMetric value in state! ' + selectedMetric)
    }
  }

  const getSelectedMetricPercentageOfTotal = (report) => {
    switch (selectedMetric.value) {
      case METRIC_IMPRESSION:
        if (report.impression) return (parseInt(report.impression) / props.worldMapReports.map(r => r.impression).reduce((partialSum, r) => partialSum + parseInt(r), 0)) * 100
        else return 0
      case METRIC_INVENTORY:
        if (report.inventory) return (parseInt(report.inventory) / props.worldMapReports.map(r => r.inventory).reduce((partialSum, r) => partialSum + parseInt(r), 0)) * 100
        else return 0
      case METRIC_REVENUE:
        if (report.revenue) return (parseInt(report.revenue) / props.worldMapReports.map(r => r.revenue).reduce((partialSum, r) => partialSum + parseInt(r), 0)) * 100
        else return 0
      case METRIC_CPM:
        if (report.ecpm) return (parseInt(report.ecpm) / props.worldMapReports.map(r => r.ecpm).reduce((partialSum, r) => partialSum + parseInt(r), 0)) * 100
        else return 0
      case METRIC_COMPLETION:
        if (report.complete) return (parseInt(report.complete) / props.worldMapReports.map(r => r.complete).reduce((partialSum, r) => partialSum + parseInt(r), 0)) * 100
        else return 0
      default:
        throw new Error('Attempted to invoke getSelectedMetricPercentageOfTotal() with an invalid selectedMetric value in state! ' + selectedMetric)
    }
  }

  const getRegionValueFromCode = (code) => {
    code = String(code)
    if (!regionValues) return
    const value = regionValues[code]
    if (value) return value
    else return 0
  }

  const calculateRegionValues = (e) => {
    if (!props.worldMapReports || !props.worldMapReports.length) return
    const regions = {}
    for (let i = 0; i < props.worldMapReports.length; i++) {
      const country = props.worldMapReports[i].country
      if (country.toLowerCase() === 'unknown') continue
      const code = countryToAlpha2(country)
      // console.log(country, '=', code)
      if (!code) {
        console.error('Could not find country, ignoring it for render purposes', country)
        continue
      }
      const value = getSelectedMetric(props.worldMapReports[i], e || undefined)
      if (!value) continue
      if (regions[code]) regions[code] = regions[code] + value
      else regions[code] = value
    }
    return regions
  }

  const getTopFiveCountries = () => {
    const sortedReports = props.worldMapReports.sort((a, b) => {
      if (getSelectedMetric(a) < getSelectedMetric(b)) return 1
      else if (getSelectedMetric(b) < getSelectedMetric(a)) return -1
      else return 0
    })
    const topFive = sortedReports.slice(0, 5)
    const rows = []
    for (let i = 0; i < topFive.length; i++) {
      const total = getSelectedMetricPercentageOfTotal(topFive[i])
      rows.push(
        <tr
          key={`world-map-table-row-${i}`}
          style={{ color: i === 0 ? THEME_COLOR_PRIMARY : '#fff' }}
        >
          <td id={`world-map-table-row-${i}-country-name`}>{topFive[i].country}</td>
          <td
            id={`world-map-table-row-${i}-value`}
            className='text-end'
          >
            {selectedMetric.value === METRIC_REVENUE || selectedMetric.value === METRIC_CPM ? '$' : ''}
            {parseFloat(getSelectedMetric(topFive[i])).toLocaleString()}
          </td>
          {selectedMetric.value !== METRIC_CPM
            ? (
              <td
                id={`world-map-table-row-${i}-percentage-of-total`}
                className='text-end'
              >
                {String(total).slice(0, String(total).indexOf('.') + 3)}%
              </td>
              )
            : null}
        </tr>
      )
    }

    return (
      <>
        {rows}
      </>
    )
  }

  return (
    <Row>
      <Col xl='12'>
        <Card
          id='world-map-card'
          style={{ marginBottom: '1rem' }}
        >
          <Card.Body>
            <Row>
              {window.innerWidth > 600
                ? (
                  <Col xs='auto'>
                    <span>Showing {selectedMetric.label} world map data. Scroll to zoom.</span>
                  </Col>
                  )
                : null}
              <Col />
              <Col xs='auto'>
                <Select
                  id='world-map-dropdown'
                  type='select'
                  styles={SELECT_STYLE}
                  isSearchable
                  maxMenuHeight={180}
                  value={selectedMetric}
                  onChange={e => {
                    setSelectedMetric(e)
                    regionValues = calculateRegionValues(e)
                    setLoadingMap(true)
                    setTimeout(() => { setLoadingMap(false) }, 800)
                  }}
                  options={[{
                    label: METRIC_IMPRESSION,
                    value: METRIC_IMPRESSION
                  }, {
                    label: METRIC_INVENTORY,
                    value: METRIC_INVENTORY
                  }, {
                    label: METRIC_REVENUE,
                    value: METRIC_REVENUE
                  }, {
                    label: METRIC_CPM,
                    value: METRIC_CPM
                  }, {
                    label: METRIC_COMPLETION,
                    value: METRIC_COMPLETION
                  }]}
                />
              </Col>
            </Row>
            <br />
            <div className='ratio ratio-21x9 mb-3 world-map-container'>
              {loadingMap
                ? (
                  <Row>
                    <Col />
                    <Col xs='auto' style={{ paddingTop: '15%' }}>
                      <ThemedSpinnerComponent />
                    </Col>
                    <Col />
                  </Row>
                  )
                : (
                  <VectorMap
                    map={worldMill}
                    backgroundColor='transparent'
                    className='jvectormap-without-padding'
                    regionStyle={{
                      initial: {
                        'fill-opacity': 0.85,
                        stroke: 'none',
                        'stroke-width': 0.6,
                        'stroke-opacity': 1
                      },
                      hover: {
                        'fill-opacity': 1,
                        stroke: 'none',
                        'stroke-width': 0.6,
                        'stroke-opacity': 1
                      }
                    }}
                    onRegionTipShow={(e, el, code) => {
                      el.html(
                        el.html() +
                        ': ' +
                        (selectedMetric.label === METRIC_REVENUE || selectedMetric.label === METRIC_CPM
                          ? '$'
                          : '') +
                        getRegionValueFromCode(code).toLocaleString()
                      )
                    }}
                    series={{
                      regions: [{
                        normalizeFunction: 'linear',
                        values: regionValues,
                        scale: [THEME_COLOR_LIGHT, THEME_COLOR_PRIMARY, THEME_COLOR_SECONDARY],
                        attribute: 'fill'
                      }]
                    }}
                    zoomOnScroll
                  />
                  )}
            </div>
            <Row className='world-map-table-container'>
              <Col>
                <table className='w-100 small mb-0 text-truncate text-white'>
                  <thead>
                    <tr className='text-white text-opacity-60'>
                      <th>COUNTRY</th>
                      <th className='text-end'>{selectedMetric.label}</th>
                      {selectedMetric.value !== 'CPM'
                        ? (<th className='text-end'>% of Total</th>)
                        : null}
                    </tr>
                  </thead>
                  <tbody>
                    {getTopFiveCountries()}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card.Body>
          <div className='card-arrow'>
            <div className='card-arrow-top-left' />
            <div className='card-arrow-top-right' />
            <div className='card-arrow-bottom-left' />
            <div className='card-arrow-bottom-right' />
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default WorldMapComponent
