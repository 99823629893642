import app from "./app";

const handleToggleClass = function () {
  var elmList = [].slice.call(document.querySelectorAll('[' + app.toggleClass.toggleAttr + ']'));

  elmList.map(function (elm) {
    elm.onclick = function (e) {
      e.preventDefault();

      var targetToggleClass = this.getAttribute(app.toggleClass.toggleAttr);
      var targetDismissClass = this.getAttribute(app.dismissClass.toggleAttr);
      var targetToggleElm = document.querySelector(this.getAttribute(app.toggleClass.targetAttr));

      if (!targetDismissClass) {
        if (targetToggleElm.classList.contains(targetToggleClass)) {
          targetToggleElm.classList.remove(targetToggleClass);
        } else {
          targetToggleElm.classList.add(targetToggleClass);
        }
      } else {
        if (!targetToggleElm.classList.contains(targetToggleClass) && !targetToggleElm.classList.contains(targetDismissClass)) {
          if (targetToggleElm.classList.contains(targetToggleClass)) {
            targetToggleElm.classList.remove(targetToggleClass);
          } else {
            targetToggleElm.classList.add(targetToggleClass);
          }
        } else {
          if (targetToggleElm.classList.contains(targetToggleClass)) {
            targetToggleElm.classList.remove(targetToggleClass);
          } else {
            targetToggleElm.classList.add(targetToggleClass);
          }
          if (targetToggleElm.classList.contains(targetDismissClass)) {
            targetToggleElm.classList.remove(targetDismissClass);
          } else {
            targetToggleElm.classList.add(targetDismissClass);
          }
        }
      }
    }
  });
}

export default handleToggleClass;