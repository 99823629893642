import React from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import Chart from 'react-apexcharts'
import CardComponent from './CardComponent'
import ThemedSpinnerComponent from './ThemedSpinnerComponent'
import {
  APEX_CHART_TYPE_AREA,
  METRIC_CPM,
  METRIC_FILLRATE,
  METRIC_REVENUE,
  THEME_COLOR_PRIMARY
} from '../constants'
import getYAxisFormatter from '../utils/getYAxisFormatter'
import getPlotOptionsByChartType from '../utils/getPlotOptionsByChartType'
import getStrokeByChartType from '../utils/getStrokeByChartType'

const MetricsCardComponent = (props) => {
  return (
    <CardComponent id={`metric-card-${props.titleText.replaceAll(' ', '-')}`}>
      {props.fetchingReports
        ? (
          <>
            &ensp;&ensp;
            <ThemedSpinnerComponent />
          </>
          )
        : (
          <Row>
            <Col xs='7'>
              <div className='d-flex fw-bold small mb-3'>
                <span
                  className='flex-grow-1'
                  style={{ fontSize: '0.9rem' }}
                >
                  {props.titleText}
                </span>
              </div>
              <div className='row align-items-center mb-2'>
                <h3
                  id={`main-metric-text-${props.titleText.replaceAll(' ', '-')}`}
                  className='mb-0'
                >
                  {props.mainMetricText}
                </h3>
              </div>
              <div className='small text-white text-opacity-50 text-truncate'>
                <div id={`sub-metric-text-1-${props.titleText.replaceAll(' ', '-')}`}>
                  {props.subMetricTextOne && props.subMetricIconOne
                    ? (
                      <>
                        <i className={`fa ${props.subMetricIconOne} fa-fw me-1`} />
                        {props.subMetricTextOne}
                        <br />
                      </>
                      )
                    : null}
                </div>
                <div id={`sub-metric-text-2-${props.titleText.replaceAll(' ', '-')}`}>
                  {props.subMetricTextTwo && props.subMetricIconTwo
                    ? (
                      <>
                        <i className={`fa ${props.subMetricIconTwo} fa-fw me-1`} />
                        {props.subMetricTextTwo}
                        <br />
                      </>
                      )
                    : null}
                </div>
                <div id={`sub-metric-text-3-${props.titleText.replaceAll(' ', '-')}`}>
                  {props.subMetricTextThree && props.subMetricIconThree
                    ? (
                      <>
                        <i className={`fa ${props.subMetricIconThree} fa-fw me-1`} />
                        {props.subMetricTextThree}
                        <br />
                      </>
                      )
                    : null}
                </div>
              </div>
            </Col>
            <Col xs='5'>
              {props.chartSeries && props.showCharts
                ? (
                  <Chart
                    type={APEX_CHART_TYPE_AREA}
                    height='100'
                    options={{
                      chart: {
                        toolbar: {
                          show: false
                        },
                        sparkline: {
                          enabled: false
                        }
                      },
                      dataLabels: {
                        enabled: false
                      },
                      colors: [THEME_COLOR_PRIMARY],
                      stroke: getStrokeByChartType(APEX_CHART_TYPE_AREA),
                      markers: {
                        shape: 'circle',
                        colors: '#2a343f',
                        size: 3,
                        strokeColors: THEME_COLOR_PRIMARY,
                        strokeWidth: 1,
                        hover: {
                          size: 5,
                          sizeOffset: 3
                        }
                      },
                      plotOptions: getPlotOptionsByChartType(APEX_CHART_TYPE_AREA),
                      xaxis: {
                        show: false,
                        categories: props.chartCategories || {},
                        labels: {
                          show: false
                        },
                        axisBorder: {
                          show: false
                        },
                        axisTicks: {
                          show: false
                        }
                      },
                      yaxis: {
                        show: false,
                        labels: {
                          show: false,
                          formatter: getYAxisFormatter(props.titleText)
                        }
                      },
                      grid: {
                        show: false
                      },
                      tooltip: {
                        theme: 'dark',
                        x: {
                          show: false
                        },
                        y: {
                          title: {
                            formatter: function (seriesName) {
                              return ''
                            }
                          },
                          formatter: (value) => {
                            if (props.titleText === METRIC_REVENUE || props.titleText === METRIC_CPM) return '$' + value.toLocaleString()
                            if (props.titleText === METRIC_FILLRATE) return value + '%'
                            else return value.toLocaleString()
                          }
                        }
                      }
                    }}
                    series={props.chartSeries}
                  />
                  )
                : null}
            </Col>
          </Row>
          )}
    </CardComponent>
  )
}

export default MetricsCardComponent
