import app from "./app";

const handleScrollToTopButton = function () {
  var elmTriggerList = [].slice.call(document.querySelectorAll('[' + app.scrollToTopBtn.toggleAttr + ']'));

  document.onscroll = function () {
    var doc = document.documentElement;
    var totalScroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    var elmList = elmTriggerList.map(function (elm) {
      if (totalScroll >= app.scrollToTopBtn.heightShow) {
        if (!elm.classList.contains(app.scrollToTopBtn.showClass)) {
          elm.classList.add(app.scrollToTopBtn.showClass);
        }
      } else {
        elm.classList.remove(app.scrollToTopBtn.showClass);
      }
    });

    var elm = document.querySelectorAll(app.id)[0];

    if (totalScroll > 0) {
      elm.classList.add(app.header.hasScrollClass);
    } else {
      elm.classList.remove(app.header.hasScrollClass);
    }
  }

  var elmList = elmTriggerList.map(function (elm) {
    elm.onclick = function (e) {
      e.preventDefault();

      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  });
};

export default handleScrollToTopButton;