import { Tooltip } from 'bootstrap';
import app from './app';

const handleCardAction = function () {
  if (app.card.expand.status) {
    return false;
  }
  app.card.expand.status = true;

  // expand
  var expandTogglerList = [].slice.call(document.querySelectorAll('[' + app.card.expand.toggleAttr + ']'));
  var expandTogglerTooltipList = expandTogglerList.map(function (expandTogglerEl) {
    expandTogglerEl.onclick = function (e) {
      e.preventDefault();

      var target = this.closest('.card');
      var targetClass = app.card.expand.class;
      var targetTop = 40;

      if (document.body.classList.contains(targetClass) && target.classList.contains(targetClass)) {
        target.removeAttribute('style');
        target.classList.remove(targetClass);
        document.body.classList.remove(targetClass);
      } else {
        document.body.classList.add(targetClass);
        target.classList.add(targetClass);
      }

      window.dispatchEvent(new Event('resize'));
    };

    return new Tooltip(expandTogglerEl, {
      title: app.card.expand.toggleTitle,
      placement: 'bottom',
      trigger: 'hover',
      container: 'body'
    });
  });
};

export default handleCardAction;