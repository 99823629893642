import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import handleScrollbar from './template/handleScrollbar'
import handleSidebarMenu from './template/handleSidebarMenu'
import handleSidebarScrollMemory from './template/handleSidebarScrollMemory'
import handleCardAction from './template/handleCardAction'
import handelTooltipPopoverActivation from './template/handleTooltipPopoverActivation'
import handleScrollToTopButton from './template/handleScrollToTopButton'
import handleScrollTo from './template/handleScrollTo'
import handleToggleClass from './template/handleToggleClass'
import handleThemePanel from './template/handleThemePanel'
import HeaderComponent from './components/HeaderComponent'
import SidebarComponent from './components/SidebarComponent'
import MobileSidebarBackdropComponent from './components/MobileSidebarBackdropComponent'
import ScrollToTopButtonComponent from './components/ScrollToTopButtonComponent'
import ContentRoot from './ContentRoot'
import LoginRoot from './LoginRoot'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'js-cookie'

const randomNumber = require('random-number')

const initComponent = function () {
  handleScrollbar()
  handleScrollToTopButton()
  handleScrollTo()
  handleCardAction()
  handelTooltipPopoverActivation()
  handleToggleClass()
  handleThemePanel()
  handleBackCover()
}

const handleBackCover = () => {
  document.getElementById('html-tag').setAttribute('class', `bg-cover-${randomNumber({
    min: 1,
    max: 16,
    integer: true
  })}`)
}

const initSidebar = function () {
  handleSidebarMenu()
  handleSidebarScrollMemory()
}

const App = () => {
  useEffect(() => {
    initComponent()
    initSidebar()
  }, [])

  return (
    <Router>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} theme='colored' />
      {Cookies.get('api-token')
        ? (
          <>
            <HeaderComponent
              allowMenuToggle
              showUserLink
            />
            <SidebarComponent />
            <MobileSidebarBackdropComponent />
            <ContentRoot />
            <ScrollToTopButtonComponent />
          </>
          )
        : (
          <>
            <LoginRoot />
          </>
          )}
    </Router>
  )
}

export default App
