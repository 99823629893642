import React from 'react'
import { GSM_HOMEPAGE_URL } from '../constants'

const LogoBannerComponent = () => {
  return (
    <div className='brand brand-do-not-disable-on-small-screens'>
      <a
        href={GSM_HOMEPAGE_URL}
        className='brand-logo'
      >
        <img
          src='assets/img/gsm-logo.png'
          alt='Global Sun Media Homepage'
          height='36'
        />
      </a>
      <a
        href='/dashboard'
        className='brand-logo'
      >
        <span className='brand-text'>VIDEO PLAYER METRICS</span>
      </a>
    </div>
  )
}

export default LogoBannerComponent
