import Cookies from 'js-cookie'
import React, { useState } from 'react'
import {
  Row,
  Col,
  Form
} from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getApiToken } from '../api/api'
import ButtonComponent from '../components/ButtonComponent'
import ThemedSpinnerComponent from '../components/ThemedSpinnerComponent'

const handleLogin = async (email, password, setLoggingIn) => {
  setLoggingIn(true)
  const data = await getApiToken(email, password)
  if ((!data || !data.access_token || !data.user) || data instanceof Error) {
    setLoggingIn(false)
    toast.error('Error! Check your details and connection and try again.')
    return
  }
  Cookies.set('api-token', data.access_token)
  Cookies.set('user-name', data.user.name)
  Cookies.set('user-username', data.user.username)
  Cookies.set('user-email', data.user.email)
  Cookies.set('user-avatar', data.user.avatar)
  window.location.href = '/'
}

const LoginView = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loggingIn, setLoggingIn] = useState(false)

  return (
    <>
      <Row style={{ margin: '0px' }}>
        <Col>
          <div
            style={{
              width: '100%',
              height: '10vh'
            }}
          />
        </Col>
      </Row>
      <Row style={{ margin: '0px' }}>
        <Col />
        <Col
          xl='4'
          lg='6'
          md='8'
        >
          {loggingIn
            ? (
              <Row style={{ paddingTop: '5rem' }}>
                <Col />
                <Col xs='auto'>
                  <ThemedSpinnerComponent />
                </Col>
                <Col />
              </Row>
              )
            : (
              <div
                style={{
                  width: '100%',
                  height: '400px'
                }}
              >
                <Row>
                  <Col style={{ padding: '0px' }} />
                  <Col style={{ padding: '0px' }}>
                    <img
                      src='assets/img/gsm-logo-full.png'
                      alt='Global Sun Logo'
                      height='120'
                    />
                  </Col>
                  <Col style={{ padding: '0px' }} />
                </Row>
                <br />
                <br />
                <br />
                <Form>
                  <Form.Group
                    className='mb-3'
                    controlId='formBasicEmail'
                    id='email-input'
                  >
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter email'
                      value={email}
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                    <Form.Text className='text-muted'>
                      &ensp;We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='formBasicPassword'
                    id='password-input'
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Enter password'
                      value={password}
                      onChange={(e) => { setPassword(e.target.value) }}
                    />
                  </Form.Group>
                  <br />
                  <Row>
                    <Col />
                    <Col xs='auto'>
                      <ButtonComponent
                        id='login-button'
                        text='Login'
                        iconClass={(!email || !password) ? 'bi-three-dots' : 'bi-arrow-right'}
                        onClick={() => { handleLogin(email, password, setLoggingIn) }}
                        disabled={!email || !password}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
              )}
        </Col>
        <Col />
      </Row>
    </>
  )
}

export default LoginView
